<template>
  <keep-alive>
    <router-view />
  </keep-alive>
</template>

<script lang="ts">
import { Vue, Component } from "vue-property-decorator";

@Component
export default class AccountIndex extends Vue {}
</script>
